import React from "react"
import { Container } from "@material-ui/core"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const StyledContainer = styled(Container)`
  height: 100vh;
  text-align: center;
  min-width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5));
`

const StyledBackgroundSection = ({ children, data }) => {
  return (
    <BackgroundImage
      fluid={data.image.childImageSharp.fluid}
    >
      <StyledContainer component='section'>
        {children}
      </StyledContainer>
    </BackgroundImage>
  )
}

const HeroImage = styled(StyledBackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
`
export default HeroImage
