import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  CorePrinciples,
  About,
  Expertise,
  Services,
  Clients
} from "../components/organisms";
import { Hero } from "../components/Hero";

const IndexPage = props => (
  <Layout route={props.location.pathname}>
    <SEO title="Home" />
    <Hero />
    <CorePrinciples />
    <About />
    <Expertise />
    <Services />
    <Clients />
  </Layout>
);

export default IndexPage;
