import React from "react";
import { Container, Typography } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import ArrowDown from "@material-ui/icons/ArrowDownward";
import HeroImage from "./HeroImage";

const SiteBranding = styled(Container)`
  position: absolute;
  top: 15em;
  left: 0;
  right: 0;
  text-transform: uppercase;
  color: white;
`;
const BrandText = styled(({ ...props }) => <Typography {...props} />)`
  letter-spacing: 9px;
  margin-bottom: 8px;
`;

const Arrow = styled(ArrowDown)`
  color: #ffc30e;
  display: block;
  margin: 0 auto;
  cursor: pointer;
`;
const MenuScroll = styled(Container)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  padding: 1em 1em 3em;
`;

export const Hero = () => {
  const bgImage = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "construction-helmet-on-black.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const scrollTo = () => {
    window.scroll({
      top: 850,
      left: 0,
      behavior: "smooth"
    });
  };
  return (
    <HeroImage data={bgImage}>
      <SiteBranding component="header">
        <Typography variant="h2" as="h1" gutterBottom>
          Paving Excellence
        </Typography>
        <Typography variant="h2" as="h1" gutterBottom>
          Since 1975
        </Typography>
        <br />
        <BrandText variant="h6" as="h2">
          Industrial - Commercial - Residential
        </BrandText>
      </SiteBranding>
      <MenuScroll>
        <Typography variant="overline">Learn More</Typography>
        <Arrow onClick={scrollTo} />
      </MenuScroll>
    </HeroImage>
  );
};

// export default Hero
